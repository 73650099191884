import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

export default class ConsoleLogsService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = () => `${settings.baseUrl}/api`;
  }

  async List(params?) {
    return await this.Get<logs.item[]>('/console-logs', params);
  }

  async Count(params?) {
    return await this.Get<number>('/console-logs/count', params);
  }
}

export const consoleLogsService = new ConsoleLogsService();