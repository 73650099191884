import { Vue, Component, Prop } from 'vue-property-decorator';
import { logsService } from "../services/logsService";
import FilterLogs from "../components/filterLogs.vue"
import CtsExportOperationLogs from '../components/ctsExportOperationLogs.vue';
import { capitalizeFirstLetter } from '@/utils/utils';

@Component({
  components: {
    FilterLogs,
    CtsExportOperationLogs
  }
})
export default class Logs extends Vue {

  pagination: { pageSizes: number[], total: number, } = {
    pageSizes: [20, 50, 100],
    total: 0
  }

  query: logs.queryParams = {
    text: null,
    username: null,
    type: null,
    orderField: 'CreatedDate',
    orderDescending: true,
    page: 1,
    take: 20,
  };

  list: logs.summary[] = [];
  loading: boolean = false;

  get filterActive() {
    return !!this.query.type || !!this.query.username || (!!this.query.text && this.query.text !== '');
  }

  created() {
    this.fetchData();
  }

  validateRowClassName({ row }) {
    return 'log-type-' + row.type;
  }

  async fetchData() {
    this.loading = true;
    this.pagination.total = await logsService.Count(this.query);
    this.list = await logsService.List(this.query);
    this.loading = false;
  }

  handleFilter() {
    this.query.page = 1;
    this.fetchData();
  }

  handleSizeChange(val: number) {
    this.query.take = val;
    this.query.page = 1;
    this.fetchData();
  }

  handleCurrentChange(val: number) {
    this.query.page = val;
    this.fetchData();
  }

  sortChange({ column, prop, order }) {
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    this.fetchData();
  }

  onFilterChange(query: logs.queryParams) {
    this.query = query;
    this.fetchData();
  }

  onFilterClean(query: logs.queryParams) {
    this.query = query;
    this.fetchData();
  }

  onSelect(value: logs.summary) {
    this.$router.push(`/timeline/logs/${value.id}`)
  }
}