import { Vue, Prop, Component } from "vue-property-decorator";
import RightPanel from "@/components/RightPanel/index.vue";
@Component({
  components: {
    RightPanel
  }
})

export default class FilterConsoleLogs extends Vue {

  @Prop({ required: true })
  query: consoleLogs.queryParams;

  logTypesDisplay = ['Unclassified', 'Success', 'Warning', 'Error', 'Info', 'Exception'];

  get filterActive(): boolean {
    return (this.query &&
      (
        !!this.query.text ||
        !!this.query.type
      ));
  }

  handleFilter() {
    this.query.page = 1;
    (this.$refs.filterPanel as any).show = false;
    this.$emit('change', this.query);
  }

  handleClean() {
    (this.$refs.filterPanel as any).show = false;
    this.$emit('clean', {
      text: null,
      type: null,
      orderField: 'CreatedDate',
      orderDescending: true,
      page: 1,
      take: 10,
    } as consoleLogs.queryParams);
  }
}