import { Vue, Component, Prop } from 'vue-property-decorator';
import { consoleLogsService } from "../services/consoleLogsService";
import FilterConsoleLogs from "../components/filterConsoleLogs.vue"
import { capitalizeFirstLetter } from '@/utils/utils';

@Component({
  components: {
    FilterConsoleLogs
  }
})
export default class ConsoleLogs extends Vue {

  pagination: { pageSizes: number[], total: number, } = {
    pageSizes: [20, 50, 100],
    total: 0
  }

  query: consoleLogs.queryParams = {
    text: null,
    type: null,
    orderField: 'CreatedDate',
    orderDescending: true,
    page: 1,
    take: 20,
  };

  list: consoleLogs.item[] = [];
  loading: boolean = false;

  get filterActive() {
    return !!this.query.type || (!!this.query.text && this.query.text !== '');
  }

  created() {
    this.fetchData();
  }

  validateRowClassName({ row }) {
    return 'log-type-' + row.type;
  }

  async fetchData() {
    this.loading = true;
    this.pagination.total = await consoleLogsService.Count(this.query);
    this.list = await consoleLogsService.List(this.query);
    this.loading = false;
  }

  handleFilter() {
    this.query.page = 1;
    this.fetchData();
  }

  handleSizeChange(val: number) {
    this.query.take = val;
    this.query.page = 1;
    this.fetchData();
  }

  handleCurrentChange(val: number) {
    this.query.page = val;
    this.fetchData();
  }

  sortChange({ column, prop, order }) {
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    this.fetchData();
  }

  onFilterChange(query: consoleLogs.queryParams) {
    this.query = query;
    this.fetchData();
  }

  onFilterClean(query: consoleLogs.queryParams) {
    this.query = query;
    this.fetchData();
  }
}