import { Vue, Prop, Component } from "vue-property-decorator";
import RightPanel from "@/components/RightPanel/index.vue";
import { logsService } from "../services/logsService";
import { ElForm } from "element-ui/types/form";
import * as XLSX from 'xlsx';
import { formatTime } from "@/filters";
@Component({
  components: {
    RightPanel
  }
})

export default class CtsExportOperationLogs extends Vue {
  model: { interval: any[] } = { interval: null }

  get formRules() {
    return {
      interval: { required: true, message: `Inserisci un intervallo di date`, trigger: 'blur' }
    };
  }

  pickerOptions = {
    shortcuts: [{
      text: 'Last week',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: 'Last month',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: 'Last 3 months',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  };

  downloading: boolean = false;
  async handleExport() {
    (this.$refs.exportLogsForm as ElForm).validate(async valid => {
      if (valid) {
        this.downloading = true;
        const from = formatTime(this.model.interval[0], 'YYYY-MM-DD');
        const to = formatTime(this.model.interval[1], 'YYYY-MM-DD');
        const logs = await logsService.CtsExportOperations(from, `${to} 23:59:59`);

        const fileName = `CTS_attività_${from}_${to}.xlsx`;
        const ws = XLSX.utils.json_to_sheet(logs);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Attività CTS');
        XLSX.writeFile(wb, fileName);

        this.downloading = false;
      }
    });
  }
}