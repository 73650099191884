import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { logsService } from "../services/logsService";

import vueJsonCompare from "vue-json-compare";

@Component({
  components: {
    vueJsonCompare,
  }
})

export default class LogDetail extends Vue {
  @Prop()
  id: string;

  detail: logs.item = null;
  activeTab: string = "generale";

  created() {
    this.fetchDetail()
  }

  @Watch("id")
  changeId(n, o) {
    if (n && n !== o) {
      this.fetchDetail();
    }
  }

  async fetchDetail() {
    this.detail = await logsService.Detail(this.id);
  }
}