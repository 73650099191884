import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

export default class LogsService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = () => `${settings.baseUrl}/api`;
  }

  async Count(params?) {
    return await this.Get<number>('/logs/count', params);
  }

  async List(params?) {
    return await this.Get<logs.summary[]>('/logs', params);
  }

  async Detail(id: string): Promise<logs.item> {
    return await this.Get(`/logs/${id}`);
  }

  async CtsExportOperations(from: string, to: string) {
    return await this.Get<logs.ctsExportOperation[]>('/logs/cts/movement-logs', { from, to });
  }
}

export const logsService = new LogsService();